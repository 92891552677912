<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Status Approval</label>
                     <Dropdown id="status" v-model="filters.status" :options="dataDropdownStatus" optionLabel="name" optionValue="code" placeholder="Pilih Status" />
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Tipe Sales</label>
                     <Dropdown id="tipe_sales" v-model="filters.tipe_sales" :options="dataDropdownTipeSales" :showClear="true" optionLabel="name" optionValue="code" placeholder="Pilih Tipe Sales" />
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

export default {
    data() {
        return {
            // loading
            loading: false,

            // dataDropdown
            dataDropdownStatus: [
                {name: 'Pending', code: 'Pending'},
                {name: 'Approved', code: 'Approved'},
                {name: 'Rejected', code: 'Rejected'},
            ],
            dataDropdownTipeSales: [
                {name: 'Tender', code: 'TENDER'},
                {name: 'Non Tender', code: 'NON TENDER'},
                {name: 'Regular', code: 'REGULAR'},
            ],

            // filter
            filters: {
                tipe_sales: null,
                status: 'Pending',
            },
        }
    },
    methods: {
        getDataTable(){
            this.$emit('submit');
        }
    }
}
</script>

